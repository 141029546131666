"use client";
import React, { useEffect, useState } from "react";
import HomepageCarousel from "@/components/Homepage/HomepageCarousel";
import ClientOnly from "@/components/common/ClientOnly";
import { getCatalogueTopMaxInvestedList } from "@/services/catalogueServices";
import {
  formatTotalInvestment,
  findMinMaxValues,
  createBrandDetailsPageSlug,
} from "../../../utils/helper";
import Link from "next/link";
import ImageThumbnail from "@/components/common/ImageThumbnail/ImageThumbnail";
import SkeletonCard from "@/components/common/Skeleton/SkeletonCard/SkeletonCard";

const InvestedCategory = () => {
  const [state, setState] = useState({
    maxInvestedList: [],
  });
  const [loading, setLoading] = useState(false);

  const { maxInvestedList } = state;

  const fetchCatalogueMaxInvestedList = async () => {
    setLoading(true);
    try {
      const response = await getCatalogueTopMaxInvestedList();
      if (response?.status === "Success") {
        setState((prev) => ({ ...prev, maxInvestedList: response?.data }));
      }
    } catch (error) {
      console.log("eeeeeeeeee", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCatalogueMaxInvestedList();
  }, []);
  //console.log("maxInvestedList", maxInvestedList);
  return (
    <div className="bg-primary">
      <div className="py-3lg:py-8 container  mx-auto">
        {loading ? (
          <SkeletonCard />
        ) : (
          <ClientOnly>
            <HomepageCarousel
              title="Best Franchise Business Options"
              subtitle=""
              dots={true}
              slidesToScroll={5}
              slidesToShow={5}
            >
              {maxInvestedList?.map((el) => {
                const investedRange = findMinMaxValues([el], "totalinvestment");
                const areaRange = findMinMaxValues([el], "spacearea");
                return (
                  <div
                    className="relative mx-[18px] rounded-s  border border-gray-200 bg-white shadow-xs"
                    key={el._id}
                  >
                    <Link
                      href={createBrandDetailsPageSlug(el?.brandname, el?._id)}
                      className="relative"
                    >
                      <ImageThumbnail
                        src={el?.brandlogo}
                        alt="image"
                        width={100}
                        height={127}
                        paddingBottom="50%"
                      />
                    </Link>
                    <div className="pt-3">
                      <div className="px-3 pb-3">
                        <a href="#">
                          <h5 className="mb-2 text-sm font-semibold tracking-tight text-color-grey">
                            {el?.brandname}
                          </h5>
                        </a>
                        <div className="mb-1 flex justify-between">
                          <p className="text-xs font-medium text-color-secondary-dark">
                            Investment amount
                          </p>
                          <p className="text-xs font-medium text-color-secondary-dark">
                            ₹{formatTotalInvestment(investedRange?.min)}
                            {investedRange?.max > 0
                              ? ` - ${formatTotalInvestment(
                                  investedRange?.max
                                )}`
                              : ""}
                          </p>
                        </div>
                        <div className="mb-1 flex justify-between">
                          <p className="text-xs font-medium text-color-secondary-dark">
                            Area Req.
                          </p>
                          <p className="text-xs font-medium text-color-secondary-dark">
                            {areaRange?.min}
                            {areaRange?.max > 0 ? ` - ${areaRange?.max}` : ""}
                          </p>
                        </div>
                        <div className="mb-1 flex justify-between">
                          <p className="text-xs font-medium text-color-secondary-dark">
                            Total franchise
                          </p>
                          <p className="text-xs font-medium text-color-secondary-dark">
                            {el?.brandoutlets}
                          </p>
                        </div>

                        {/* <p className="mt-2 text-xxs font-medium text-color-grey">
                           Most recognised Franchise brand
                         </p> */}
                      </div>
                      <Link
                        href={createBrandDetailsPageSlug(
                          el?.brandname,
                          el?._id
                        )}
                        className="inline-flex h-[40px] w-full items-center justify-center rounded-bl rounded-br  bg-tertiary px-3 py-2 text-sm font-medium  text-color-black hover:bg-primary-color hover:text-white"
                      >
                        Know more
                      </Link>
                    </div>
                  </div>
                );
              })}
            </HomepageCarousel>
          </ClientOnly>
        )}
      </div>
    </div>
  );
};

export default InvestedCategory;
