"use client";

import React from "react";
import Slider from "react-slick";
import Image from "next/image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroBannerClient = ({ slides, isMobile }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <div className="homepage__banner__slider w-full bg-primary">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <a
            key={index}
            className="relative h-[150px] md:h-[300px]"
            href={slide.linkUrl}
            target="_self"
          >
            <div
              className={`${
                isMobile
                  ? "mt-3 flex items-center justify-center"
                  : "absolute bottom-0 left-0 right-0 top-0 -z-10 w-full"
              }`}
            >
              <Image
                src={isMobile ? slide.mobile : slide.desktop}
                alt={`Banner ${index + 1}`}
                fill={true}
                // sizes="(max-width: 768px) 100vw, (min-width: 768px) 100vw"
                style={{ objectFit: "cover" }}
                unoptimized={true}
                className={
                  isMobile ? "relative h-[160px] w-[92%] rounded-lg" : ""
                }
              />
            </div>
          </a>
        ))}
      </Slider>
    </div>
  );
};

export default HeroBannerClient;
