import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Compare_brands.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/compare_image.jpeg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Connect_with_Brands.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/connection.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/ellipse.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/find.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/HomepageBannerImages/homepage_banner_desktop_1.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/HomepageBannerImages/homepage_banner_desktop_2.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/HomepageBannerImages/homepage_banner_desktop_3.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/HomepageBannerImages/homepage_banner_desktop_4.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/HomepageBannerImages/homepage_banner_desktop_5.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/HomepageBannerImages/homepage_banner_mobile_1.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/HomepageBannerImages/homepage_banner_mobile_2.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/HomepageBannerImages/homepage_banner_mobile_3.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/HomepageBannerImages/homepage_banner_mobile_4.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/HomepageBannerImages/homepage_banner_mobile_5.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Legal_assistance.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/Post_Your_requirement.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/RelationshipManager.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/request.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/team.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/vip_image.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/common/LazyLoadSection.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layouts/Footer/DesktopFooter.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layouts/Header/DesktopHeader.js");
;
import(/* webpackMode: "eager" */ "/app/src/components/layouts/Header/header.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layouts/Header/HeaderWrapper.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layouts/Header/MobileHeader/MobileHeader.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layouts/Newsletter.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/Auth/AuthModalWrapper.js");
;
import(/* webpackMode: "eager" */ "/app/src/features/LandingPage/Category/HomepageCategory.js");
;
import(/* webpackMode: "eager" */ "/app/src/features/LandingPage/ExploreVideos/ExploreVideos.js");
;
import(/* webpackMode: "eager" */ "/app/src/features/LandingPage/FeaturedBlogs/FeaturedBlogs.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/LandingPage/HeroBannerClient.js");
;
import(/* webpackMode: "eager" */ "/app/src/features/LandingPage/InvestedCategory/InvestedCategory.js");
;
import(/* webpackMode: "eager" */ "/app/src/features/LandingPage/LearningCenter/LearningCenter.js");
;
import(/* webpackMode: "eager" */ "/app/src/features/LandingPage/pyr/PostYourRequirment.js");
;
import(/* webpackMode: "eager" */ "/app/src/features/LandingPage/TopFranchises/TopFranchises.js");
;
import(/* webpackMode: "eager", webpackExports: ["RootStyleRegistry"] */ "/app/src/providers/RootStyleRegistry.js");
